import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {
    const [timeRemaining, setTimeRemaining] = useState(getTimeUntilNextTarget());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeRemaining(getTimeUntilNextTarget());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    function getTimeUntilNextTarget() {
        const now = new Date();
        const currentHour = now.getHours();
        const currentMinutes = now.getMinutes();
        const currentSeconds = now.getSeconds();

        const targetHours = [0, 4, 8, 12, 16, 20];
        let nextTargetHour = targetHours.find(hour => hour > currentHour);

        if (nextTargetHour === undefined) {
            nextTargetHour = 24;
        }

        const targetTime = new Date();
        targetTime.setHours(nextTargetHour, 0, 0, 0);

        const timeDifference = targetTime - now;

        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        return { hours, minutes, seconds };
    }

    return (
        <div>
            {timeRemaining.hours}h {timeRemaining.minutes}m {timeRemaining.seconds}s
        </div>
    );
};

export default CountdownTimer;
