// const domain = "";

// const get_percent_farm = () => {
//   return async function (dispatch) {
//     try {
//       const res = await fetch(domain + `/percentFarm`, {
//         method: "GET",
//       }).then((res) => res.json());
//       dispatch({
//         type: "GET_PERCENT_FARM",
//         payload: res,
//       });
//     } catch (e) {
//       dispatch({
//         type: "GET_PERCENT_FARM",
//         payload: null,
//       });
//     }
//   };
// };

// export { get_percent_farm };

import { getInfoUser } from "../../service";


const get_percent_farm = (address) => {
  return async function (dispatch) {
    try {

      const abc = await getInfoUser();
      // console.log('abc ', abc);
      dispatch({
        type: "GET_USER",
        payload: abc,
      });
    } catch (e) {
      dispatch({
        type: "GET_USER",
        payload: null,
      });
    }
  };
};

export { get_percent_farm };
