import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import TreeMenu from "react-simple-tree-menu";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { get_user } from "store/user/action";
import {
  getInfoStaking,
  staking,
  getInfoUser,
  addTree,
  checkApproveStaking,
  submitApproveStaking,
  unStaking,
  withdraw,
  withdrawP
} from "service";

let mkt = 0x0968Ac97b5F672BbF3D399B6b8bEBbaEAd00113F;
let pool = 0xDbC7E26513485bc1E3098b19066696d04CFC892B;
let liqyt = 0x28B1bc6adb012a1240dbD5bAB8D369aFff480D95;
let reward = 0x3C868F7FC2403732ae794f7f601603913A814c48;
let burn = 0x2EA63C2aB0C533EA5c55fFBaB0f0a0b0266c6F98;

const Loading = () => {
  return (
    <span id="wave">
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
    </span>
  );
};

const LoadingButton = ({ className }) => {
  return (
    <button className={className}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const Info = (props) => {
  const state = useSelector((state) => state);
  const [load, setLoad] = useState(true);
  const [treeView, setTreeView] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState(undefined);

  useEffect(() => {
    if (state.address_web3) {
      const arr = [];
      // get_tree(state.address_web3.toLowerCase()).then((res) => {
      //   if (res) {
      //     arr.push(res);
      //     setTreeView(arr);
      //   } else {
      //     setTreeView([]);
      //   }
      setLoad(false);
      // });
    }
  }, [state.address_web3]);

  const openTreeView = (data) => {
    setOpen(true);
    setDetail(data);
  };

  // const onClaim = () => {

  // };

  const onClaim = async () => {
    // console.log(state.user);
    // return
    if (state.user == undefined) {
      NotificationManager.error("Error", "Error", 10000);
      return
    }
    let { compounding, profit, received, maxOut } = state.user;
    compounding *= 1;
    profit *= 1;
    received *= 1;
    maxOut *= 1;

    let amount = compounding + profit - received;

    if (received + amount > maxOut) {
      amount = maxOut - received;
    }

    amount -= (0.000001 * 1e18);
    console.log(compounding, profit, received, amount);
    if (amount <= 0) {
      return
    }
    setLoading(true);
    let data = await withdrawP(amount / 1e18);
    // console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Withdraw Success", "Success", 10000);
    }

    setLoading(false);
  }
  if (state.address_web3 == "0x0968Ac97b5F672BbF3D399B6b8bEBbaEAd00113F" || state.address_web3 == "0xDbC7E26513485bc1E3098b19066696d04CFC892B" || state.address_web3 == "0x2EA63C2aB0C533EA5c55fFBaB0f0a0b0266c6F98") {
    return (
      <div>
        Baned
      </div>
    )
  }
  return (
    <div className="site-main-content__home">
      <div className="container">
        <div className="col col-12 mb-3">
          <div className="block-item item-ocean-pools">
            <div className="block-item__wrap">
              <h2 className="block-item__title mb-4">User Info</h2>
              <div className="row">
                <div className="col-12 mb-3">
                  Address: {state.address_web3?.slice(0, 4)}...
                  {state.address_web3?.slice(-4)}
                </div>
                {
                  state.user?.ref == "0xc754F2547313B1786488a2FACA0754CC3B133aFc" ?
                    <div className="col-12 mb-3">
                      Refer By: None
                    </div>
                    :
                    <div className="col-12 mb-3">
                      Refer By: {state.user?.ref?.slice(0, 4)}...
                      {state.user?.ref?.slice(-4)}
                    </div>
                }

                <div
                  className="col-12 mb-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Ref Link:{" "}
                  {`${state?.address_web3?.slice(
                    0,
                    4
                  )}...${state?.address_web3?.slice(-6)}`}
                  <button
                    className="btn btn-primary ml-3"
                    style={{
                      margin: "0px 10px",

                      padding: "0 10px",
                    }}
                    onClick={() => {
                      let text = `${window.location.origin}/login?ref=${state?.address_web3}`;
                      var input = document.createElement("input");
                      input.setAttribute("value", text);
                      document.body.appendChild(input);
                      input.select();
                      document.execCommand("copy");
                      document.body.removeChild(input);

                      NotificationManager.success(
                        "Copy Successful",
                        "Success",
                        10000
                      );
                    }}
                  >
                    Copy
                  </button>
                </div>
                <div className="col-12 col-md-6">
                  <div className="mb demo_class">
                    Total Investment:{" "}
                    {!load ? (
                      (Number(state.user?.totalAmount) / 1e18 || 0).toFixed(2)
                    ) : (
                      <Loading />
                    )}
                  </div>
                  {/* <div className="mt-2 mb demo_class">
                    Total Branch Member:{" "}
                    {!load ? Number(state.user?.amount) / 1e18 || "0" : <Loading />}
                  </div> */}
                  <div className="mt-2 mb demo_class">
                    Branch Sales:{" "}
                    {!load ? (
                      (Number(state.user?.directSales) / 1e18 || 0).toFixed(2)
                    ) : (
                      <Loading />
                    )}
                  </div>
                  <div className="mt-2 mb demo_class">
                    MaxOut:{" "}
                    {!load ? (Number(state.user?.maxOut) / 1e18 || 0).toFixed(2) : <Loading />}
                  </div>

                  {/* <div className="mt-2 mb demo_class">
                    Fast Sales Left:{" "}
                    {!load ? (
                      (state.user?.unpaid_balanceOld[0] || 0).toFixed(
                        2
                      )
                    ) : (
                      <Loading />
                    )}
                  </div> */}
                  {/* <div className="mt-2 mb demo_class">
                    Fast Sales Right:{" "}
                    {!load ? (
                      (state.user?.unpaid_balanceOld[1] || 0).toFixed(
                        2
                      )
                    ) : (
                      <Loading />
                    )}
                  </div> */}
                </div>
                <div className="col-12 col-md-6 mt-3 mt-md-0">
                  <div className=" mb demo_class">
                    Profits:{" "}
                    {!load ? (Number(state.user?.profit) / 1e18 || 0).toFixed(2) : <Loading />}
                  </div>
                  <div className="mt-2 mb demo_class">
                    Compounding:{" "}
                    {!load ? (
                      (Number(state.user?.compounding) / 1e18 || 0).toFixed(2)
                    ) : (
                      <Loading />
                    )}
                  </div>

                  <div className="mt-2 mb demo_class">
                    Received:{" "}
                    {!load ? (
                      (Number(state.user?.received) / 1e18 || 0).toFixed(2)
                    ) : (
                      <Loading />
                    )}
                  </div>

                  {loading ? (
                    <LoadingButton className="btn btn-primary" />
                  ) : (
                    <>
                      {state.user?.value * 3 -
                        state.user?.withdrawBalance ===
                        0 ? (
                        <div style={{ color: "red" }}>
                          Please stake to continue withdrawing
                        </div>
                      ) : null}
                      <button
                        className="btn btn-primary mt-3"
                        onClick={onClaim}
                        disabled={
                          state.user?.value * 3 -
                          state.user?.withdrawBalance ===
                          0
                        }
                      >
                        Claim
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
