import { contract_address } from "config";
import { contract_abi } from "config";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import Web3 from "web3";
import {
  getInfoStaking,
  staking,
  getInfoUser,
  addTree,
  checkApproveStaking,
  submitApproveStaking,
  unStaking,
  withdraw,
  claimMissionDaily,
  claimMission,
  openBox,
  formatNumber,
  withdraw_s,
  withdrawNode,
  activeNode,
  getDataTree,
  validationNode,
  checkApproveStakeV2,
  stakingV2,
  submitApproveStakeV2,
  withdraw_s_stake_v2,
  updateVersionV22,
  swap,
  submitApproveSwap,
  quickStake,
  updateVersionV11,
  submitApproveDepV11,
  tranferTea,
  tranferGame
} from "service";
import CountdownTimer from "lib/CountdownTimer";
import { Link } from "react-router-dom";
import Navs from "lib/Nav";

let isUpdate = 0;
const LoadingButton = ({ className }) => {
  return (
    <button className={className} style={{ width: 45 }}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const Staking = (props) => {
  const state = useSelector((state) => state);
  const [typeSl, setTypeSl] = useState('1');
  const [typeSl1, setTypeSl1] = useState('1');
  const [typeSlOption, setTypeSlOption] = useState('5');
  const [typeStake, setTypeStake] = useState('1');
  const [loading, setLoading] = useState(false);
  const [profit, setProfit] = useState(0);
  const [usernameGame, setUsernameGame] = useState('');
  const [amountSwap, setAmountSwap] = useState(0);
  const [amountDeposit, setAmountDeposit] = useState(0);
  const [balanceUsdt, setBalanceUsdt] = useState(0);
  const [balanceTea, setBalanceTea] = useState(0);
  const [typeSwap, setTypeSwap] = useState(1);
  const [approve, setApprove] = useState(false);
  const [data, setData] = useState({});
  const [showMissionClaim, setShowMissionClaim] = useState({
    mission0: false,
    mission1: false,
    mission2: false,
    mission3: false,
    mission4: false,
    mission5: false,
  });
  const [packageStaking, setPackageStaking] = useState({});
  const [BNB, setBNB] = useState(0);
  const [SFT, setSFT] = useState(0);
  const [balance, setBalance] = useState(0);
  const [approveStakeV2, setApproveStakeV2] = useState(false);

  const [price, setPrice] = useState({
    symbolPrice: 0,
    priceSFT: 0,
    priceBNB: 0,
    bnb: 0,
  });

  // useEffect(() => {
  //   setInterval(() => {
  //     getData();
  //   }, 1000);

  // }, [typeStake]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      getData();
    }, 1000);

    // Cleanup interval on component unmount or when typeStake changes
    return () => clearInterval(intervalId);
  }, [typeStake]);

  // useEffect(() => {
  //   console.log(state.user);
  //   // getProfit();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state.user]);

  // const getData = async () => {
  //   // console.log('start');
  //   const infoStaking = await getInfoStaking();

  //   setData(infoStaking)
  // };


  const _approveStakeV2 = async () => {
    setLoading(true);
    let data = await submitApproveStakeV2();
    console.log(data);
    if (!data) {

    }

    setLoading(false);
    getData();
    return true
  }

  const getData = async () => {
    // console.log('start');
    const infoStaking = await getInfoStaking(typeStake);
    const checkApp = await checkApproveStaking();
    const checkAppStakeV2 = await checkApproveStakeV2();

    // console.log(infoStaking?.stake);
    if (!infoStaking) {
      return
    }
    if (window.localStorage.getItem("mission0") == "true") {
      setShowMissionClaim(prevState => ({
        ...prevState,
        mission0: true
      }));
    }
    if (window.localStorage.getItem("mission1") == "true") {
      setShowMissionClaim(prevState => ({
        ...prevState,
        mission1: true
      }));
    }

    if (window.localStorage.getItem("mission2") == "true") {
      setShowMissionClaim(prevState => ({
        ...prevState,
        mission2: true
      }));
    }
    if (window.localStorage.getItem("mission3") == "true") {
      setShowMissionClaim(prevState => ({
        ...prevState,
        mission3: true
      }));
    }
    if (window.localStorage.getItem("mission4") == "true") {
      setShowMissionClaim(prevState => ({
        ...prevState,
        mission4: true
      }));
    }
    console.log(infoStaking);

    if (isUpdate == 0 && infoStaking?.stake?.idGame) {
      setUsernameGame(infoStaking.stake.idGame);
      isUpdate = 1
    }
    setData(infoStaking)
    // console.log(infoStaking?.userMint);
    // console.log(checkApp.stt);
    setApprove(checkApp.stt);
    setBalanceTea(checkAppStakeV2.balance)
    setBalanceUsdt(checkApp.balance)
    setApproveStakeV2(checkAppStakeV2.stt);
    let obj = {};

    obj.pt = infoStaking?.stake?.types;
    obj.pt = obj.pt * 1 > 10 ? obj.pt / 10 : obj.pt;

    obj.amount = Number(infoStaking?.stake?.amount) / 1e18;
    obj.received = Number(infoStaking?.stake?.received) / 1e18;
    obj.timestamp = Number(infoStaking?.stake?.timestamp);

    let times = Math.floor(Date.now() / 1000) - obj.timestamp;
    obj.profit = (obj.amount * times * obj.pt / 259200000) - obj.received;
    // obj.profit = (obj.profit * 1000000) / infoStaking?.priceTea;

    setPackageStaking(obj)
  };


  const _approve = async () => {
    setLoading(true);
    let data = await submitApproveStaking();
    console.log(data);
    if (!data) {

    }

    setLoading(false);
    getData();
  }



  const missionGo = (type) => {
    switch (type) {
      case 0:
        window.open("https://t.me/teafriendsglb/43", "_blank");
        window.localStorage.setItem("mission0", 'true')
        setShowMissionClaim(prevState => ({
          ...prevState,
          mission0: true
        }));
        break;
      case 1:
        window.open("https://t.me/teafriendsglb", "_blank");
        window.localStorage.setItem("mission1", 'true')
        setShowMissionClaim(prevState => ({
          ...prevState,
          mission1: true
        }));
        break;
      case 2:
        window.open("https://twitter.com/TeaCoinOffical", "_blank");
        window.localStorage.setItem("mission2", 'true')
        setShowMissionClaim(prevState => ({
          ...prevState,
          mission2: true
        }));
        break;
      case 3:
        window.open("https://twitter.com/TeaCoinOffical", "_blank");
        window.localStorage.setItem("mission3", 'true')
        setShowMissionClaim(prevState => ({
          ...prevState,
          mission3: true
        }));
        break;
      case 4:
        window.open("https://www.youtube.com/@Teacoin", "_blank");
        window.localStorage.setItem("mission4", 'true')
        setShowMissionClaim(prevState => ({
          ...prevState,
          mission4: true
        }));
        break;
      default:
        setShowMissionClaim(prevState => ({
          ...prevState,
          mission5: true
        }));
        break;
    }
  }

  const missionClaimDaily_ = async () => {
    setLoading(true);
    let data = await claimMissionDaily();
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }

    setLoading(false);
    getData();
  }

  const missionClaim_ = async (type) => {
    setLoading(true);
    let data = await claimMission(type);
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }

    setLoading(false);
    getData();
  }

  const openBox_ = async () => {
    setLoading(true);
    let data = await openBox();
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }

    setLoading(false);
    getData();
  }

  const stakeV2_ = async (type) => {

    setLoading(true);
    let data
    if (type == 1) {
      data = await stakingV2(0, type);
    } else {
      console.log(approveStakeV2);
      if (!approveStakeV2) {
        await _approveStakeV2()
      }
      let quantity = prompt('Please fill in quantity');
      if (!quantity) {
        setLoading(false);
        return
      }
      // console.log(approve);
      data = await stakingV2(quantity, type);
    }
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }

    setLoading(false);
    getData();
  }

  const stake_ = async (type) => {

    setLoading(true);
    let data
    if (type == 1) {
      data = await staking(0, type);
    } else {
      console.log(approve);
      if (!approve) {
        await _approve()
      }
      let quantity = prompt('Please fill in quantity');
      if (!quantity) {
        setLoading(false);
        return
      }
      // console.log(approve);
      data = await staking(quantity, type);
    }
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }

    setLoading(false);
    getData();
  }

  const claim_ = async () => {
    setLoading(true);
    let data = await withdraw_s();
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }

    setLoading(false);
    getData();
  }


  const claimStakeV2_ = async () => {
    setLoading(true);
    let data = await withdraw_s_stake_v2();
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }

    setLoading(false);
    getData();
  }

  const claim_node = async () => {
    setLoading(true);
    let data = await withdrawNode();
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }

    setLoading(false);
    getData();
  }

  const activeNode_ = async () => {
    setLoading(true);
    if (!approve) {
      await _approve()
    }
    let data = await activeNode();
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }

    setLoading(false);
    getData();
  }

  const validationNode_ = async () => {
    setLoading(true);
    // if (!approve) {
    //   await _approve()
    // }
    let data = await validationNode();
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }

    setLoading(false);
    getData();
  }


  const getDataTree_ = async () => {
    setLoading(true);
    let data = await getDataTree();
    console.log(data);
    if (data) {
      window.localStorage.setItem("tree", JSON.stringify(data));
    }
    setLoading(false);
  }
  const openTree_ = async () => {
    window.open("/assets/tree.html", "_blank");
  }

  const changeTypeStake = async (type_) => {
    // console.log(type_);
    getData();
    setTypeStake(type_);
  }

  const updatev2_ = async () => {
    setLoading(true);
    let data = await updateVersionV22();
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);
    getData();
  }

  const updatev1_ = async () => {
    if (!usernameGame) {
      NotificationManager.error("Please enter your in-game username", "Error", 10000);
      return false;
    }
    setLoading(true);
    let data = await updateVersionV11(usernameGame);
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);
    getData();
    return true;
  }

  const swap_ = async () => {
    setLoading(true);

    if (typeSwap == 1 && data.amountApproveUsdt < amountSwap) {
      await submitApproveSwap(1);
    }
    if (typeSwap == 2 && data.amountApproveTea < amountSwap) {
      await submitApproveSwap(2);
    }

    let data1 = await swap(typeSwap, amountSwap);
    console.log(data1);
    if (!data1) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);
    getData();
  }

  const quickStake_ = async () => {
    setLoading(true);

    if (data.amountApproveUsdt < amountSwap) {
      await submitApproveSwap(1);
    }
    if (data.amountApproveTea < amountSwap) {
      await submitApproveSwap(2);
    }

    let data1 = await quickStake(amountSwap);
    console.log(data1);
    if (!data1) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);
    getData();
  }

  const tranferAll_ = async () => {
    if (usernameGame != data.stake.idGame) {
      let datsup = await updatev1_();
      if (!datsup) {
        return
      }
    }

    if (typeSlOption == '1' || typeSlOption == '2' || typeSlOption == '3' || typeSlOption == '4') {
      tranfer_(typeSlOption);
    } else if (typeSlOption == '5') {
      tranferTea_()
    } else if (typeSlOption == '6') {
      claim_()
    } else {
      return
    }
  }

  const tranfer_ = async (type_) => {
    console.log(type_);
    setLoading(true);
    // let quantity = prompt('Please fill in quantity');
    if (!amountDeposit) {
      setLoading(false);
      return
    }
    let data = await tranferGame(type_, amountDeposit);
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);
  }

  const tranferTea_ = async () => {
    setLoading(true);

    if (data.amountApproveTeaV11 < amountDeposit) {
      await submitApproveDepV11();
    }

    let data1 = await tranferTea(amountDeposit);
    console.log(data1);
    if (!data1) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);
    getData();
  }




  return (

    <div className="site-main-content__buy-sft">
      <div style={{ height: 50 }}></div>
      <Navs />
      <div style={{}}>
        <img class="" src="/assets/images/card.png" alt="tea logo" style={{ width: "100%" }} />
      </div>
      <div style={{ height: 30 }}></div>

      <div style={{ textAlign: 'center' }}>
        {/* {
          state?.address_web3 == "0x37a09B5afe0E5cd97de3fbDd28961dA25e15Bb5b" ?
            <div>
              Ref By: None
            </div>
            :
            <div>
              Ref By: {`${data?.user?.ref?.slice(0, 10)}...${data?.user?.ref?.slice(-16)}`}
            </div>
        } */}

        <button className="btn btn-primary" onClick={() => {
          let text = `${window.location.origin}/login?ref=${state?.address_web3}`;
          var input = document.createElement("input");
          input.setAttribute("value", text);
          document.body.appendChild(input);
          input.select();
          document.execCommand("copy");
          document.body.removeChild(input);

          NotificationManager.success(
            "Copy Successful",
            "Success",
            10000
          );
        }}>Copy Link</button>
      </div>
      <div>
        <div className="row m-0">
          <div className="col-6 lppglgreas">
            <div className="lslsaldksad" onClick={() => { openBox_() }}>
              <img class="" src="/assets/images/2.png" alt="tea logo" className="lalsdoaowdgfg" />
              {/* <div className="pppolflef">
                <img class="" src="/assets/images/3.png" alt="tea logo" />
              </div> */}
              <div className="pppolflef">
                <div className=" mt-2 mr-2">{data?.user?.box}</div>
              </div>
            </div>
          </div>

          <div className="col-6 lppglgreas">
            <div className="lslsaldksad">
              <img class="" src="/assets/images/6.png" alt="tea logo" className="lalsdoaowdgfg" />
              <div className="pppolflef">
                <div className=" mt-2 mr-2">
                  <CountdownTimer />
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 lppglgreas">
            <div className="lslsaldksad">
              <img class="" src="/assets/images/4.png" alt="tea logo" className="lalsdoaowdgfg" />

              <div className="pppolflef">
                <div className=" mt-2 mr-2">{formatNumber((data?.user?.point || 0) / 1e18)}</div>
              </div>
              <div className="pppolflef" onClick={() => { stake_(1) }} style={{ textAlign: 'right' }}>

                {
                  loading ?
                    <LoadingButton className="btn btn-primary" />
                    :
                    <img class="" src="/assets/images/5.png" alt="tea logo" />
                }
              </div>
            </div>
          </div>
          <div className="col-6 lppglgreas">
            <div className="lslsaldksad">
              <img class="" src="/assets/images/7.png" alt="tea logo" className="lalsdoaowdgfg" />
              {/* <div className="pppolflef">
                <img class="" src="/assets/images/3.png" alt="tea logo" />
              </div> */}
              <div className="pppolflef">
                <div className=" mt-2 mr-2">{data?.user?.tea}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: 30 }}></div>

      {/* <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <div className="lellllewewewe">
          <div style={{ textAlign: 'center', fontSize: 20, fontWeight: 700, marginBottom: 10 }}>Update Username Game</div>
          <div className="mb-3">
            <div>Username Game</div>
            <input className="lsllsldlawww" value={usernameGame} onChange={(e) => setUsernameGame(e.target.value)} />
          </div>
          {
            <div style={{ textAlign: 'center' }}>
              {
                loading ?
                  <LoadingButton className="btn btn-primary" />
                  :
                  <button onClick={updatev1_} className="btn btn-primary">Update</button>
              }
            </div>
          }
        </div>
      </div> */}

      <div style={{ height: 30 }}></div>
      <div style={{ textAlign: 'center' }}>
        <button onClick={() => { setTypeSl1('1') }} className="btn btn-primary mr-2" style={{ marginRight: 20 }}>Tranfer Tea</button>
        <button onClick={() => { setTypeSl1('2') }} className="btn btn-primary">Swap Now</button>
      </div>
      <div style={{ height: 20 }}></div>
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>

        {
          typeSl1 == '1' ?
            <div className="lellllewewewe">
              <div style={{ textAlign: 'center', fontSize: 20, fontWeight: 700, marginBottom: 10 }}>Tranfer Tea To Game</div>


              <label class="container2" onClick={(e) => { setTypeSlOption('5') }}>{formatNumber(balanceTea)} Tea
                <input type="checkbox" checked={typeSlOption == '5'} />
                <span class="checkmark"></span>
              </label>

              <label class="container2" onClick={(e) => { setTypeSlOption('1') }}>{data?.userMint?.teaTreeNft} NFTea
                <input type="checkbox" checked={typeSlOption == '1'} />
                <span class="checkmark"></span>
              </label>

              <label class="container2" onClick={(e) => { setTypeSlOption('2') }}>{data?.userMint?.seedsNfts} Seed
                <input type="checkbox" checked={typeSlOption == '2'} />
                <span class="checkmark"></span>
              </label>

              <label class="container2" onClick={(e) => { setTypeSlOption('3') }}>{data?.userMint?.teaTreeFree} Tea Free
                <input type="checkbox" checked={typeSlOption == '3'} />
                <span class="checkmark"></span>
              </label>

              <label class="container2" onClick={(e) => { setTypeSlOption('4') }}>{data?.userMint?.pot} Pot
                <input type="checkbox" checked={typeSlOption == '4'} />
                <span class="checkmark"></span>
              </label>

              <label class="container2" onClick={(e) => { setTypeSlOption('6') }}>{packageStaking?.profit?.toFixed(4)} USDT
                <input type="checkbox" checked={typeSlOption == '6'} />
                <span class="checkmark"></span>
              </label>
              {
                typeSlOption != '6' ?
                  <>
                    <div>Quantity</div>
                    <input className="lsllsldlawww" value={amountDeposit} onChange={(e) => setAmountDeposit(e.target.value)} />
                  </>
                  :
                  null
              }

              {/* <div className="mb-3"> */}
              <div>Username Game</div>
              <input className="lsllsldlawww" value={usernameGame} onChange={(e) => setUsernameGame(e.target.value)} />
              {/* </div> */}

              <div style={{ textAlign: 'center', marginTop: 10 }}>
                {
                  loading ?
                    <LoadingButton className="btn btn-primary" />
                    :
                    <button onClick={tranferAll_} className="btn btn-primary">Tranfer</button>
                }
              </div>
            </div>
            :
            <div className="lellllewewewe">
              <div style={{ textAlign: 'center', fontSize: 20, fontWeight: 700, marginBottom: 10 }}>Swap Now</div>
              {
                typeSwap == 1 ?
                  <>
                    <div>Balance: {formatNumber(balanceUsdt)} USDT</div>
                    <input className="lsllsldlawww" value={amountSwap} onChange={(e) => setAmountSwap(e.target.value)} />
                  </>
                  :
                  <>
                    <div>Balance: {formatNumber(balanceTea)} TEA</div>
                    <input className="lsllsldlawww" value={amountSwap} onChange={(e) => setAmountSwap(e.target.value)} />
                  </>
              }

              <div style={{ textAlign: 'center' }} onClick={() => { setTypeSwap(typeSwap == 1 ? 2 : 1) }}>
                <img src="https://cdn-icons-png.freepik.com/512/4113/4113100.png" className="ooooosadwds" />
              </div>
              {
                typeSwap == 1 ?
                  <>
                    <div>Balance: {formatNumber(balanceTea)} TEA</div>
                    <input className="lsllsldlawww" value={amountSwap / data?.priceTea} />
                  </>
                  :
                  <>
                    <div>Balance: {formatNumber(balanceUsdt)} USDT</div>
                    <input className="lsllsldlawww" value={amountSwap * data?.priceTea} />
                  </>
              }
              <div style={{ textAlign: 'center', marginTop: 10 }}>
                {
                  loading ?
                    <LoadingButton className="btn btn-primary" />
                    :
                    <button onClick={swap_} className="btn btn-primary">Swap Now</button>
                }
                {
                  typeSwap == 2 ? null :
                    loading ?
                      <LoadingButton className="btn btn-primary" />
                      :
                      <button onClick={quickStake_} className="btn btn-primary" style={{ marginLeft: 15 }}>Quick Stake</button>
                }
              </div>
            </div>
        }
      </div>




      <div style={{ height: 30 }}>

      </div>
      <div className="row m-0">
        <img className="ppllllasd2 col-4" onClick={() => setTypeSl("1")} src={`/assets/images/${typeSl == '1' ? 12 : 10}.png`} alt="tea logo" />
        <img className="ppllllasd2 col-4" onClick={() => setTypeSl("2")} src={`/assets/images/${typeSl == '2' ? 13 : 11}.png`} alt="tea logo" />
        <img className="ppllllasd2 col-4" onClick={() => setTypeSl("3")} src={`/assets/images/${typeSl == '3' ? 14 : 9}.png`} alt="tea logo" />
      </div>
      <div style={{ height: 30 }}></div>
      {
        typeSl == '1' ?

          <div>
            <img class="" src="/assets/images/20.png" alt="tea logo" width={120} />
            <div style={{ height: 15 }}></div>
            <div className="ppplgwefe">
              <img src="/assets/images/22.png" style={{ width: '100%' }} />
              <div className="ladpwkkgrrg row m-0 p-4 pt-4">
                {/* <div className="clortllas">Subscribe</div> */}
                <div className="col-8">
                  <div className="llllfleas">
                    <img src="/assets/images/2.png" style={{ width: 50 }} />
                    +1
                  </div>
                </div>
                {
                  data?.mission?.mission5 == data?.currentNumber ?
                    <img className="col-4" src="/assets/images/24.png" style={{ width: 100 }} />
                    :
                    // (
                    //   showMissionClaim.mission5 ?
                    //     (
                    loading ?
                      <LoadingButton className="btn btn-primary" />
                      :
                      <img onClick={() => { missionClaimDaily_(5) }} className="col-4" src="/assets/images/17.png" style={{ width: 100 }} />
                  // )
                  //     :
                  //     <img onClick={() => { missionGo(5) }} className="col-4" src="/assets/images/16.png" style={{ width: 100 }} />
                  // )
                }
              </div>
            </div>
            <div style={{ height: 30 }}></div>
            <img class="" src="/assets/images/15.png" alt="tea logo" width={200} />
            <div style={{ height: 15 }}></div>
            <div className="ppplgwefe">
              <img src="/assets/images/21.png" style={{ width: '100%' }} />
              <div className="ladpwkkgrrg row m-0 p-4 pt-2">
                <div className="clortllas">Join Channel</div>
                <div className="col-8">
                  <div className="llllfleas">
                    <img src="/assets/images/2.png" style={{ width: 50 }} />
                    +1
                  </div>
                </div>
                {/* <img className="col-4" src="/assets/images/16.png" style={{ width: 100 }} /> */}
                {
                  data?.mission?.mission0 == '1' ?
                    <img className="col-4" src="/assets/images/24.png" style={{ width: 100 }} />
                    :
                    (
                      showMissionClaim.mission0 ?
                        (
                          loading ?
                            <LoadingButton className="btn btn-primary" />
                            :
                            <img onClick={() => { missionClaim_(0) }} className="col-4" src="/assets/images/17.png" style={{ width: 100 }} />
                        )
                        :
                        <img onClick={() => { missionGo(0) }} className="col-4" src="/assets/images/16.png" style={{ width: 100 }} />
                    )
                }

                <div style={{ height: 15 }}></div>
                <div className="clortllas">Join Group</div>
                <div className="col-8">
                  <div className="llllfleas">
                    <img src="/assets/images/2.png" style={{ width: 50 }} />
                    +1
                  </div>
                </div>
                {/* <img className="col-4" src="/assets/images/16.png" style={{ width: 100 }} /> */}
                {
                  data?.mission?.mission1 == '1' ?
                    <img className="col-4" src="/assets/images/24.png" style={{ width: 100 }} />
                    :
                    (
                      showMissionClaim.mission1 ?
                        (
                          loading ?
                            <LoadingButton className="btn btn-primary" />
                            :
                            <img onClick={() => { missionClaim_(1) }} className="col-4" src="/assets/images/17.png" style={{ width: 100 }} />
                        )
                        :
                        <img onClick={() => { missionGo(1) }} className="col-4" src="/assets/images/16.png" style={{ width: 100 }} />
                    )
                }
              </div>
            </div>
            <div style={{ height: 30 }}></div>
            <img class="" src="/assets/images/19.png" alt="tea logo" width={200} />
            <div style={{ height: 15 }}></div>
            <div className="ppplgwefe">
              <img src="/assets/images/21.png" style={{ width: '100%' }} />
              <div className="ladpwkkgrrg row m-0 p-4 pt-2">
                <div className="clortllas">Retweet the airdrop post</div>
                <div className="col-8">
                  <div className="llllfleas">
                    <img src="/assets/images/2.png" style={{ width: 50 }} />
                    +1
                  </div>
                </div>
                {/* <img className="col-4" src="/assets/images/16.png" style={{ width: 100 }} /> */}
                {
                  data?.mission?.mission2 == '1' ?
                    <img className="col-4" src="/assets/images/24.png" style={{ width: 100 }} />
                    :
                    (
                      showMissionClaim.mission2 ?
                        (
                          loading ?
                            <LoadingButton className="btn btn-primary" />
                            :
                            <img onClick={() => { missionClaim_(2) }} className="col-4" src="/assets/images/17.png" style={{ width: 100 }} />
                        )
                        :
                        <img onClick={() => { missionGo(2) }} className="col-4" src="/assets/images/16.png" style={{ width: 100 }} />
                    )
                }

                <div style={{ height: 15 }}></div>
                <div className="clortllas">Follow on twitter</div>
                <div className="col-8">
                  <div className="llllfleas">
                    <img src="/assets/images/2.png" style={{ width: 50 }} />
                    +1
                  </div>
                </div>
                {/* <img className="col-4" src="/assets/images/16.png" style={{ width: 100 }} /> */}
                {
                  data?.mission?.mission3 == '1' ?
                    <img className="col-4" src="/assets/images/24.png" style={{ width: 100 }} />
                    :
                    (
                      showMissionClaim.mission3 ?
                        (
                          loading ?
                            <LoadingButton className="btn btn-primary" />
                            :
                            <img onClick={() => { missionClaim_(3) }} className="col-4" src="/assets/images/17.png" style={{ width: 100 }} />
                        )
                        :
                        <img onClick={() => { missionGo(3) }} className="col-4" src="/assets/images/16.png" style={{ width: 100 }} />
                    )
                }

              </div>
            </div>
            <div style={{ height: 30 }}></div>
            <img class="" src="/assets/images/18.png" alt="tea logo" width={200} />
            <div style={{ height: 15 }}></div>
            <div className="ppplgwefe">
              <img src="/assets/images/22.png" style={{ width: '100%' }} />
              <div className="ladpwkkgrrg row m-0 p-4 pt-2">
                <div className="clortllas">Subscribe</div>
                <div className="col-8">
                  <div className="llllfleas">
                    <img src="/assets/images/2.png" style={{ width: 50 }} />
                    +1
                  </div>
                </div>
                {/* <img className="col-4" src="/assets/images/16.png" style={{ width: 100 }} /> */}
                {
                  data?.mission?.mission4 == '1' ?
                    <img className="col-4" src="/assets/images/24.png" style={{ width: 100 }} />
                    :
                    (
                      showMissionClaim.mission4 ?
                        (
                          loading ?
                            <LoadingButton className="btn btn-primary" />
                            :
                            <img onClick={() => { missionClaim_(4) }} className="col-4" src="/assets/images/17.png" style={{ width: 100 }} />
                        )
                        :
                        <img onClick={() => { missionGo(4) }} className="col-4" src="/assets/images/16.png" style={{ width: 100 }} />
                    )
                }
              </div>
            </div>

          </div>
          : null
      }
      {
        typeSl == '2' ?
          <>
            <div style={{ textAlign: 'center' }}>
              <button disabled={typeStake == '1'} onClick={() => { changeTypeStake('1') }} className="btn btn-primary" style={{ marginRight: 10 }}>v1</button>
              <button disabled={typeStake == '2'} onClick={() => { changeTypeStake('2') }} className="btn btn-primary">v2</button>
            </div>
            {
              typeStake == '1' ?
                <>
                  {/* <div style={{ height: 20 }}></div> */}
                  <div style={{ padding: 20, paddingBottom: 10 }}>
                    <div>Username Game</div>
                    <input className="lsllsldlawww" value={usernameGame} onChange={(e) => setUsernameGame(e.target.value)} />
                  </div>
                  {
                    // !data?.stake?.isUpdated ?
                    <div style={{ textAlign: 'center' }}>
                      {
                        loading ?
                          <LoadingButton className="btn btn-primary" />
                          :
                          <button onClick={updatev1_} className="btn btn-primary">Update</button>
                      }
                    </div>
                    // : null
                  }
                  <div className="ppplgwefe">


                    <img src="/assets/images/23.png" style={{ width: '100%' }} />
                    <div className="ladpwkkgrrg row m-0">
                      <div className="row m-0 clllppgowd">
                        <div className="mkkkaskds col-4">
                          Speed
                        </div>
                        <div className="mkkkaskds1 col-8">
                          <img src="/assets/images/25.png" style={{ width: '100%', position: 'absolute', top: 0, left: 0, height: "100%" }} />
                          <div style={{ position: 'absolute', zIndex: 2 }}>{(data?.stake?.types / 10)?.toFixed(1)}%</div>
                        </div>
                      </div>
                      <div className="row m-0 clllppgowd">
                        <div className="mkkkaskds col-4">
                          Mining
                        </div>
                        <div className="mkkkaskds1 col-8">
                          <img src="/assets/images/25.png" style={{ width: '100%', position: 'absolute', top: 0, left: 0, height: "100%" }} />
                          <div style={{ position: 'absolute', zIndex: 2 }}>${formatNumber((data?.stake?.amount || 0) / 1e18)}</div>
                          {/* <div className="pppolflef" onClick={() => { stake_(3) }} style={{ textAlign: 'right' }}>
                            {
                              loading ?
                                <LoadingButton className="btn btn-primary" />
                                :
                                <img class="m-0" style={{ width: 50 }} src="/assets/images/5.png" alt="tea logo" />
                            }
                          </div> */}
                        </div>
                      </div>
                      <div className="row m-0 clllppgowd">
                        <div className="mkkkaskds col-4">
                          Booster
                        </div>
                        <div className="mkkkaskds1 col-8">
                          <img src="/assets/images/25.png" style={{ width: '100%', position: 'absolute', top: 0, left: 0, height: "100%" }} />
                          <div style={{ position: 'absolute', zIndex: 2 }}>${formatNumber((data?.user?.pointRef || 0) / 1e18)}</div>
                          {/* <div className="pppolflef" onClick={() => { stake_(1) }} style={{ textAlign: 'right' }}>
                            {
                              loading ?
                                <LoadingButton className="btn btn-primary" />
                                :
                                <img class="m-0" style={{ width: 50 }} src="/assets/images/5.png" alt="tea logo" />
                            }
                          </div> */}
                        </div>
                      </div>
                      <div className="row m-0 clllppgowd">
                        <div className="mkkkaskds col-4">
                          Earn
                        </div>
                        <div className="mkkkaskds1 col-8">
                          <img src="/assets/images/25.png" style={{ width: '100%', position: 'absolute', top: 0, left: 0, height: "100%" }} />
                          <div style={{ position: 'absolute', zIndex: 2 }}>${packageStaking?.profit?.toFixed(8)}</div>
                          <div className="pppolflef" onClick={() => { claim_() }} style={{ textAlign: 'right' }}>
                            {
                              loading ?
                                <LoadingButton className="btn btn-primary" />
                                :
                                <img class="m-0" style={{ width: 60 }} src="/assets/images/17.png" alt="tea logo" />
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </>

                :
                <>
                  <div style={{ textAlign: 'center', marginTop: 10, background: '#673a1d' }}>Max: {formatNumber((data?.poolStake || 0) / 1e18)}/25,600,000 Tea</div>

                  {/* <div style={{ textAlign: 'center', background: '#673a1d' }}>totalClaimed: ${formatNumber((data?.stake?.totalClaimed || 0) / 1e18)}</div> */}
                  {
                    !data?.stake?.isUpdated ?
                      <div style={{ textAlign: 'center', marginTop: 10 }}>
                        {
                          loading ?
                            <LoadingButton className="btn btn-primary" />
                            :
                            <button onClick={updatev2_} className="btn btn-primary">Update</button>
                        }
                      </div>
                      : null
                  }


                  <div className="ppplgwefe">


                    <img src="/assets/images/23.png" style={{ width: '100%' }} />
                    <div className="ladpwkkgrrg row m-0">
                      <div className="row m-0 clllppgowd">
                        <div className="mkkkaskds col-4">
                          Speed
                        </div>
                        <div className="mkkkaskds1 col-8">
                          <img src="/assets/images/25.png" style={{ width: '100%', position: 'absolute', top: 0, left: 0, height: "100%" }} />
                          <div style={{ position: 'absolute', zIndex: 2 }}>{(data?.stake?.types / 100)?.toFixed(1)}%</div>
                        </div>
                      </div>
                      <div className="row m-0 clllppgowd">
                        <div className="mkkkaskds col-4">
                          Mining
                        </div>
                        <div className="mkkkaskds1 col-8">
                          <img src="/assets/images/25.png" style={{ width: '100%', position: 'absolute', top: 0, left: 0, height: "100%" }} />
                          <div style={{ position: 'absolute', zIndex: 2 }}>${formatNumber((data?.stake?.amount || 0) / 1e18)}</div>
                          <div className="pppolflef" onClick={() => { stakeV2_(2) }} style={{ textAlign: 'right' }}>
                            {
                              loading ?
                                <LoadingButton className="btn btn-primary" />
                                :
                                <img class="m-0" style={{ width: 50 }} src="/assets/images/5.png" alt="tea logo" />
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 clllppgowd">
                        <div className="mkkkaskds col-4">
                          Booster
                        </div>
                        <div className="mkkkaskds1 col-8">
                          <img src="/assets/images/25.png" style={{ width: '100%', position: 'absolute', top: 0, left: 0, height: "100%" }} />
                          <div style={{ position: 'absolute', zIndex: 2 }}>${formatNumber((data?.stake?.pointRef || 0) / 1e18)}</div>
                          <div className="pppolflef" onClick={() => { stakeV2_(1) }} style={{ textAlign: 'right' }}>
                            {
                              loading ?
                                <LoadingButton className="btn btn-primary" />
                                :
                                <img class="m-0" style={{ width: 50 }} src="/assets/images/5.png" alt="tea logo" />
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row m-0 clllppgowd">
                        <div className="mkkkaskds col-4">
                          Earn
                        </div>
                        <div className="mkkkaskds1 col-8">
                          <img src="/assets/images/25.png" style={{ width: '100%', position: 'absolute', top: 0, left: 0, height: "100%" }} />
                          <div style={{ position: 'absolute', zIndex: 2 }}>${packageStaking?.profit?.toFixed(8)}</div>
                          <div className="pppolflef" onClick={() => { claimStakeV2_() }} style={{ textAlign: 'right' }}>
                            {
                              loading ?
                                <LoadingButton className="btn btn-primary" />
                                :
                                <img class="m-0" style={{ width: 60 }} src="/assets/images/17.png" alt="tea logo" />
                            }
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </>
            }

          </>
          : null
      }

      {
        typeSl == '3' ?
          <>
            <div className="ppplgwefe">
              <img src="/assets/images/23.png" style={{ width: '100%' }} />
              <div className="ladpwkkgrrg row m-0">
                {/* <div className="row m-0 clllppgowd">
                <div className="mkkkaskds col-4">
                  Speed
                </div>
                <div className="mkkkaskds1 col-8">
                  <img src="/assets/images/25.png" style={{ width: '100%', position: 'absolute', top: 0, left: 0, height: "100%" }} />
                  <div style={{ position: 'absolute', zIndex: 2 }}>{(data?.stake?.types / 30)?.toFixed(1)}%</div>
                </div>
              </div> */}
                {/* <div className="row m-0 clllppgowd">
                <div className="mkkkaskds col-4">
                  Mining
                </div>
                <div className="mkkkaskds1 col-8">
                  <img src="/assets/images/25.png" style={{ width: '100%', position: 'absolute', top: 0, left: 0, height: "100%" }} />
                  <div style={{ position: 'absolute', zIndex: 2 }}>${formatNumber((data?.stake?.amount || 0) / 1e18)}</div>
                  <div className="pppolflef" onClick={() => { stake_(2) }} style={{ textAlign: 'right' }}>
                    {
                      loading ?
                        <LoadingButton className="btn btn-primary" />
                        :
                        <img class="m-0" style={{ width: 50 }} src="/assets/images/5.png" alt="tea logo" />
                    }
                  </div>
                </div>
              </div> */}
                {
                  data?.user?.activeNode * 1 == 1 ?
                    loading ?
                      <LoadingButton className="btn btn-primary" />
                      :
                      (
                        data?.userMint?.activeNode * 1 == 1 ?
                          <button
                            className="btn btn-primary"
                            style={{ maxWidth: 150, margin: 'auto' }}
                          >
                            Node validated
                          </button>
                          :
                          <button
                            className="btn btn-primary"
                            style={{ maxWidth: 150, margin: 'auto' }}
                            onClick={() => {
                              validationNode_();
                            }}
                          >
                            Node validation
                          </button>
                      )
                    :
                    loading ?
                      <LoadingButton className="btn btn-primary" />
                      :
                      <button
                        className="btn btn-primary"
                        style={{ maxWidth: 120, margin: 'auto' }}
                        onClick={() => {
                          activeNode_();
                        }}
                      >
                        Active Node
                      </button>
                }
                <div style={{ marginTop: 8 }}></div>
                <div className="row m-0 clllppgowd">
                  <div className="mkkkaskds col-4">
                    Received
                  </div>
                  <div className="mkkkaskds1 col-8">
                    <img src="/assets/images/25.png" style={{ width: '100%', position: 'absolute', top: 0, left: 0, height: "100%" }} />
                    <div style={{ position: 'absolute', zIndex: 2 }}>${formatNumber((data?.user?.receivedUSDT || 0) / 1e18)}</div>
                  </div>
                </div>

                <div className="row m-0 clllppgowd">
                  <div className="mkkkaskds col-4">
                    Reward
                  </div>
                  <div className="mkkkaskds1 col-8">
                    <img src="/assets/images/25.png" style={{ width: '100%', position: 'absolute', top: 0, left: 0, height: "100%" }} />
                    <div style={{ position: 'absolute', zIndex: 2 }}>${formatNumber(((data?.user?.reward || 0) / 1e18) - ((data?.user?.receivedUSDT || 0) / 1e18))}</div>
                    <div className="pppolflef" onClick={() => { claim_node() }} style={{ textAlign: 'right' }}>
                      {
                        loading ?
                          <LoadingButton className="btn btn-primary" />
                          :
                          <img class="m-0" style={{ width: 60 }} src="/assets/images/17.png" alt="tea logo" />
                      }
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {/* <div style={{ textAlign: 'center' }}>
              {
                loading ?
                  <LoadingButton className="btn btn-primary" />
                  :
                  <button
                    className="btn btn-primary"
                    style={{ maxWidth: 120, margin: 'auto' }}
                    onClick={() => {
                      getDataTree_();
                    }}
                  >
                    Update Tree
                  </button>
              }
              <button
                className="btn btn-primary"
                style={{ maxWidth: 120, margin: 'auto' }}
                onClick={() => {
                  openTree_();
                }}
              >
                Open Tree
              </button>
            </div> */}
          </>
          : null
      }

      <div style={{ height: 20 }}></div>


    </div >
  );
};

export default Staking;
