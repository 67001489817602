// import React from "react";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const Home = (props) => {
  const state = useSelector((state) => state);

  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  return (
    <div >
      {/* <div style={{height: 10}}></div>   */}
      <div className="lsdlasldlwlfe" style={{ height: 40 }}></div>
      <img src="/assets/images/home.png" />
    </div>
  );
};

export default Home;
