import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal'
import {
  checkApproveStaking,
  submitApproveStaking,
  activeNode,
  getInfoStaking,
  formatNumber,
  mintPot,
  mint,
  claim,
  sell,
  checkApproveMint,
  submitApproveMint,
  tranferGame,
} from "service";
import { Link } from "react-router-dom";
import Navs from "lib/Nav";

const LoadingButton = ({ className }) => {
  return (
    <button className={className} style={{ width: 45 }}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const Bag = (props) => {
  const state = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [approve, setApprove] = useState(false);
  const [data, setData] = useState({});
  const [vlOW, setVlOW] = useState("");
  const [balance, setBalance] = useState(0);
  const [selectCG, setSelectCG] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(true);

  useEffect(() => {
    setInterval(() => {
      getData();
    }, 1000);
  }, []);

  const getData = async () => {
    // console.log('start');
    const infoStaking = await getInfoStaking();
    const checkApp = await checkApproveMint();
    setApprove(checkApp.stt);
    setBalance(checkApp.balance);
    setData(infoStaking)
  };


  const _approve = async () => {
    setLoading(true);
    let data = await submitApproveMint();
    console.log(data);
    if (!data) {

    }

    setLoading(false);
    getData();
  }

  const activeNode_ = async () => {
    setLoading(true);
    if (!approve) {
      await _approve()
    }
    let data = await activeNode();
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }

    setLoading(false);
    getData();
  }

  // console.log(data?.userMint);
  // const sell_ = (type_) => {

  // }

  const mintPot_ = async () => {
    setLoading(true);
    let data = await mintPot();
    // console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);
    getData();
  }
  const mint_ = async () => {
    setLoading(true);
    if (!approve) {
      await _approve()
    }
    let data = await mint();
    // console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);
    getData();
  }
  const claim_ = async () => {
    setLoading(true);
    let data = await claim();
    // console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);
    getData();
  }

  const sell_ = async (type_) => {
    setSelectCG(type_)
    setShowModal(true)
    // setLoading(true);
    // let data = await sell();
    // // console.log(data);
    // if (!data) {
    //   NotificationManager.error("Error", "Error", 10000);
    // } else {
    //   NotificationManager.success("Success", "Success", 10000);
    // }
    // setLoading(false);
    // getData();
  }
  const sell__ = async (event) => {
    event.preventDefault();
    console.log(selectCG, vlOW);
    setLoading(true);
    let data = await sell(vlOW, selectCG);
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      setShowModal(false)
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);

    // setLoading(true);
    // let data = await sell();
    // // console.log(data);
    // if (!data) {
    //   NotificationManager.error("Error", "Error", 10000);
    // } else {
    //   NotificationManager.success("Success", "Success", 10000);
    // }
    // setLoading(false);
    // getData();
  }

  const tranfer_ = async (type_) => {
    console.log(type_);
    setLoading(true);
    let quantity = prompt('Please fill in quantity');
    if (!quantity) {
      setLoading(false);
      return
    }
    let data = await tranferGame(type_, quantity);
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      setShowModal(false)
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);
  }

  return (

    <div className="site-main-content__buy-sft">
      <div style={{ height: 50 }}></div>
      <Navs />
      {/* <div className="p-3">
        <div>
          Total F1 Active : {data?.userMint?.totalF1Active}
        </div>
        <div>
          Total Minted : {data?.userMint?.teaUsed}
        </div>
        <div>
          <div>Mint available : {(data?.user?.tea * 1) - (data?.userMint?.teaUsed * 1)}</div>
        </div>
        <div>
          Token lock : {formatNumber(data?.userMint?.tokenLock / 1e18)}
        </div>
        <div>
          Token withdrawn : {formatNumber(data?.userMint?.withdrawTokenLock / 1e18)}
        </div>
        {
          data?.userMint?.totalF1Active * 50 >= (data?.userMint?.tokenLock / 1e18)
            ?
            <div>
              Token available : {formatNumber((data?.userMint?.tokenLock / 1e18) - (data?.userMint?.withdrawTokenLock / 1e18))}
            </div>
            :
            <div>
              Token available : {formatNumber((data?.userMint?.totalF1Active * 50) - (data?.userMint?.withdrawTokenLock / 1e18))}
            </div>
        }
        <div className="mt-2 cllpppapsasas">
          {
            loading ?
              <LoadingButton className="btn btn-primary" />
              :
              <>
                <button onClick={() => { mintPot_() }} disabled={data?.userMint?.sttGetPot} className="btn btn-primary">Mint Pot</button>
                <button onClick={() => { mint_() }} className="btn btn-primary">Mint</button>
                <button onClick={() => { claim_() }} className="btn btn-primary">Claim</button>
              </>
          }

        </div>
      </div> */}
      <div style={{ height: 20 }}></div>
      <div className="row m-0" style={{ color: "#000000", fontWeight: 500 }}>
        <div className="col-6 mt-4">
          <div className="pppploaowsdfs">
            <div className="p-1">{data?.userMint?.teaUsed}</div>
            <img src="assets/images/b2.png" className="ooollwolas" />
            <div className="pppsppspsp" onClick={() => { mint_() }}>
              <div>MINT</div>
              <div>{(data?.user?.tea * 1) - (data?.userMint?.teaUsed * 1)}</div>
            </div>
          </div>
        </div>
        <div className="col-6 mt-4">
          <div className="pppploaowsdfs" style={{ justifyContent: 'space-around' }}>
            <div className="p-1 pr-3">{data?.userMint?.totalF1Active}</div>
            <div className="pppsppspsp">
              <div className="ooooskkssw">F1 ACTIVED</div>
            </div>
          </div>
        </div>
        {
          data?.userMint?.sttGetPot ?
            <div className="col-6 mt-4">
              <div className="pppploaowsdfs">
                <div className="p-1">1</div>
                <img src="assets/images/b4.png" className="ooollwolas" />
                <div className="pppsppspsp">
                  <div>MINT</div>
                  <div>0</div>
                </div>
              </div>
            </div>
            :
            <div className="col-6 mt-4">
              <div className="pppploaowsdfs">
                <div className="p-1">0</div>
                <img src="assets/images/b4.png" className="ooollwolas" />
                <div className="pppsppspsp" onClick={() => { mintPot_() }}>
                  <div>MINT</div>
                  <div>1</div>
                </div>
              </div>
            </div>
        }

        <div className="col-6 mt-4">
          <div className="pppploaowsdfs">
            <div className="p-1">{formatNumber(data?.userMint?.withdrawTokenLock / 1e18)}</div>
            <img src="assets/images/4a.png" className="ooollwolas" style={{ width: 36 }} />
            <div className="pppsppspsp" onClick={() => { claim_() }}>
              <div>CLAIM</div>
              {
                data?.userMint?.totalF1Active * 50 >= (data?.userMint?.tokenLock / 1e18)
                  ?
                  <div>
                    {formatNumber((data?.userMint?.tokenLock / 1e18) - (data?.userMint?.withdrawTokenLock / 1e18))}
                  </div>
                  :
                  <div>
                    {formatNumber((data?.userMint?.totalF1Active * 50) - (data?.userMint?.withdrawTokenLock / 1e18))}
                  </div>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="row m-0">
        <div className="col-6 col-sm-3 mt-4">
          <div className="sadasdasds">
            <div className="sadds">
              <div className="oollsakdww">
                <img className="imgsss" src={`/assets/images/mint1.png`} />
              </div>
              <div className="price mb-2 mt-2">Balance: {data?.userMint?.teaTreeNft}</div>
              <div className="col-12 pr-0 clllasldlsdlsakwe" >
                <button className="btn btn-primary" onClick={() => { sell_(1) }}>Sell</button>
                <button className="btn btn-primary mt-2" onClick={() => { tranfer_(1) }}>Transfer Game</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-sm-3 mt-4">
          <div className="sadasdasds">
            <div className="sadds">
              <div className="oollsakdww">
                <img className="imgsss" src={`/assets/images/mint2.png`} />
              </div>
              <div className="price mb-2 mt-2">Balance: {data?.userMint?.seedsNfts}</div>
              <div className="col-12 pr-0 clllasldlsdlsakwe" >
                <button className="btn btn-primary" onClick={() => { sell_(2) }}>Sell</button>
                <button className="btn btn-primary mt-2" onClick={() => { tranfer_(2) }}>Transfer Game</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-sm-3 mt-4">
          <div className="sadasdasds">
            <div className="sadds">
              <div className="oollsakdww">
                <img className="imgsss" src={`/assets/images/mint3.png`} />
              </div>
              <div className="price mb-2 mt-2">Balance: {data?.userMint?.teaTreeFree}</div>
              <div className="col-12 pr-0 clllasldlsdlsakwe" >
                <button className="btn btn-primary" onClick={() => { sell_(3) }}>Sell</button>
                <button className="btn btn-primary mt-2" onClick={() => { tranfer_(3) }}>Transfer Game</button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-sm-3 mt-4">
          <div className="sadasdasds">
            <div className="sadds">
              <div className="oollsakdww">
                <img className="imgsss" src={`/assets/images/mint4.png`} />
              </div>
              <div className="price mb-2 mt-2">Balance: {data?.userMint?.pot}</div>
              <div className="col-12 pr-0 clllasldlsdlsakwe" >
                <button className="btn btn-primary" onClick={() => { sell_(4) }}>Sell</button>
                <button className="btn btn-primary mt-2" onClick={() => { tranfer_(4) }}>Transfer Game</button>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div style={{ height: 20 }}></div>

      {
        showModal ?
          <Modal show={show} onHide={() => { setShowModal(false) }}>
            <Modal.Header closeButton>
              <Modal.Title>Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{ color: '#fff' }}>Price (Tea)</p>
              <input style={{ width: '100%' }} className="input" type="text" id="newOwner" name="newOwner" value={vlOW} onChange={(e) => setVlOW(e.target.value)} />
            </Modal.Body>
            <Modal.Footer>
              <div className="has-text-centered">
                {
                  loading ?
                    <LoadingButton type="button" className="ant-btn ant-btn-primary btn btn-primary" />
                    :
                    (
                      <button type="submit" className="ant-btn ant-btn-primary btn btn-primary" onClick={sell__}>
                        <span>Sell</span>
                      </button>
                    )
                }
              </div>
            </Modal.Footer>
          </Modal>
          : null
      }
    </div >
  );
};

export default Bag;
