import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
const Navs = () => {
    return (
        <div className="odasodosadosa" style={{ display: 'flex', justifyContent: 'space-around' }}>
            <button
                className="btn btn-primary"
            >
                <Link to="/home" className="lsadlasdlasldasld">
                    <img src='assets/images/a2.png' />
                </Link>
            </button>
            <button className="btn btn-primary">

                <Link to="/dapp" className="lsadlasdlasldasld">
                    <img src='assets/images/a3.png' />
                </Link>
            </button>
            <button className="btn btn-primary">
                <a target="_black" href="https://pancakeswap.finance/swap?outputCurrency=0x404A20050951FDa1aDc37b9C1D049B138e661C5a&inputCurrency=0x55d398326f99059fF775485246999027B3197955" style={{ textDecoration: 'auto', color: '#fff' }}>
                    <img style={{maxWidth: 22}}  src='assets/images/a7.png' />
                </a>
            </button>
            {/* <button className="btn btn-primary">
                <a target="_black" href="https://docs.google.com/document/d/1BSnCsS2xYI6ZT-0NQXQHle7LRaIKmkZN/edit#heading=h.gjdgxs" style={{ textDecoration: 'auto', color: '#fff' }}>
                    <img src='assets/images/a4.png' />
                </a>
            </button> */}
            <button className="btn btn-primary">
                <Link to="/bag" className="lsadlasdlasldasld">
                    <img src='assets/images/a5.png' />
                </Link>
            </button>
            <button className="btn btn-primary">
                <Link to="/market" className="lsadlasdlasldasld">
                    <img src='assets/images/a6.png' />
                </Link>
            </button>
        </div>
    );
};

export default Navs;
