import { getWeb3Provider } from "service";
import Web3 from "web3";

const get_address = () => {
  return async function (dispatch) {
    const web3 = await getWeb3Provider();

    setInterval(() => {
      if (!web3) {
        return
      }
      // console.log(web3);
      web3.eth.getAccounts().then((accounts) => {
        if (accounts.length) {
          dispatch({
            type: "GET_ADDRESS",
            payload: accounts[0],
          });
        } else {
          dispatch({
            type: "GET_ADDRESS",
            payload: null,
          });
        }
      });
    }, 300);
  };
};

export { get_address };
