import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal'
import {
  getInfoStaking,
  formatNumber,
  checkApproveMint,
  submitApproveMint,
  getItemMarket,
  buy,
} from "service";
import { Link } from "react-router-dom";
import Navs from "lib/Nav";
import Web3 from "web3";

const LoadingButton = ({ className }) => {
  return (
    <button className={className} style={{ width: 45 }}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const Bag = (props) => {
  const state = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [approve, setApprove] = useState(false);
  const [data, setData] = useState({});
  const [vlOW, setVlOW] = useState("");
  const [balance, setBalance] = useState(-1);

  const [nfts, setNfts] = useState([]);
  const [filteredNfts, setFilteredNfts] = useState([]);
  const [priceOrder, setPriceOrder] = useState('asc');
  const [typeFilter, setTypeFilter] = useState(0);

  useEffect(() => {
    let filtered = nfts;
    // Lọc theo loại NFT
    if (typeFilter != 0) {
      filtered = filtered.filter(nft => parseInt(nft.typeNft, 10) === parseInt(typeFilter, 10));
    }
    // console.log(filtered);

    // Sắp xếp theo giá
    filtered = filtered.sort((a, b) => {
      const priceA = parseFloat(a.price);
      const priceB = parseFloat(b.price);
      return priceOrder === 'asc' ? priceA - priceB : priceB - priceA;
    });
    // console.log(filtered);
    setFilteredNfts(filtered);
  }, [priceOrder, typeFilter, nfts]);


  useEffect(() => {
    setInterval(() => {
      getData();
    }, 1000);
  }, []);

  // useEffect(async () => {
  //   const infoStaking = await getItemMarket();

  //   if (!infoStaking) {
  //     return
  //   }
  //   let arrc = [];
  //   for (let index = 0; index < infoStaking.length; index++) {
  //     const element = infoStaking[index];
  //     let obj = {};
  //     console.log(element.price);
  //     obj.price = Number(Web3.utils.fromWei(element.price, 'ether'));
  //     obj.author = element.author;
  //     obj.exist = element.exist;
  //     obj.id = element.id * 1;
  //     obj.typeNft = element.typeNft * 1;
  //     arrc.push(obj);
  //   }
  //   console.log(arrc);
  //   setNfts(arrc);
  //   setFilteredNfts(arrc);
  // }, [balance]);

  const getData = async () => {
    const checkApp = await checkApproveMint();
    setApprove(checkApp?.stt);
    setBalance(checkApp?.balance);

    const infoStaking = await getItemMarket();

    if (!infoStaking) {
      return
    }
    let arrc = [];
    for (let index = 0; index < infoStaking.length; index++) {
      const element = infoStaking[index];
      let obj = {};
      // console.log(element.price);
      obj.price = Number(Web3.utils.fromWei(element.price, 'ether'));
      obj.author = element.author;
      obj.exist = element.exist;
      obj.id = element.id * 1;
      obj.typeNft = element.typeNft * 1;
      arrc.push(obj);
    }
    // console.log(arrc);
    setNfts(arrc);
    setFilteredNfts(arrc);
  };


  const _approve = async () => {
    setLoading(true);
    let data = await submitApproveMint();
    console.log(data);
    if (!data) {

    }

    setLoading(false);
    getData();
  }

  const buy_ = async (id) => {
    setLoading(true);
    if (!approve) {
      await _approve()
    }
    let data = await buy(id);
    // console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);
    getData();
  }
  return (

    <div className="site-main-content__buy-sft">
      <div style={{ height: 50 }}></div>
      <Navs />

      <div style={{ height: 20 }}></div>
      <div className="p-2">


        <h1>NFT Market</h1>
        <div style={{ height: 10 }}></div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <label>
            Price:{" "}
            <select value={priceOrder} onChange={(e) => setPriceOrder(e.target.value)}>
              <option value="asc">Low to High</option>
              <option value="desc">High to Low</option>
            </select>
          </label>
          <label>
            Type:{" "}
            <select value={typeFilter} onChange={(e) => setTypeFilter(e.target.value)}>
              <option value={0}>All</option>
              <option value={1}>NFTea</option>
              <option value={2}>Seed</option>
              <option value={3}>Tea</option>
              <option value={4}>Pot</option>
            </select>
          </label>
        </div>
        <div className="row m-0">
          {filteredNfts.map((nft, index) => (
            <div className="col-6 col-sm-3 mt-4" key={index}>
              <div className="sadasdasds">
                <div className="sadds">
                  <div className="oollsakdww">
                    <img className="imgsss" src={`/assets/images/mint${nft.typeNft}.png`} />
                  </div>
                  <div className="price mb-2 mt-2">Price: {formatNumber(nft.price)} TEA</div>
                  <div className="price mb-2 mt-2">ID Market: {formatNumber(nft.id)}</div>
                  <div className="col-12 pr-0 clllasldlsdlsakwe" >
                    {
                      loading ?
                        <LoadingButton type="button" className="ant-btn ant-btn-primary btn btn-primary" />
                        :
                        <button onClick={() => { buy_(nft.id) }} className="btn btn-primary">Buy</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div >
  );
};

export default Bag;
